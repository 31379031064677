import React, { useState, useEffect } from 'react';
import { Form, DatePicker, Select, Button, Checkbox, Image } from 'components/elements/FormComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, moment, Divider } from 'components/elements/AntComponent';
import { AccountTypes, PatientTypes } from 'store/actions/service-request/newRequestActions';
import { FileTextOutlined, LoadingOutlined, DownOutlined } from 'components/elements/IconComponent';
import { getComparisonReport, getReportComparison } from 'store/actions/patient/patientActions';
import NoReportImg from 'assets/images/no-report.png';
import { Spin } from 'components/elements/CommonComponent';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useNavigate } from 'react-router-dom';
import CompareTopography from './CompareTopography';

export default function ReportComparison() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { accType, pntType, loading2, loading4 } = useSelector((state) => state.request);
    const { cmpreport, compreport, loading15, loading14, success14 } = useSelector((state) => state.patient);
    const [selectedPnt, setSelectedPnt] = useState('');
    const accTypes = accType?.data || [];
    const patientType = pntType?.data || [];
    const [selectedRpts, setSelectedRpts] = useState([]);
    const rptData = compreport ? compreport?.req_info : [];
    const [compareData, setCompareData] = useState([]);
    const [accID, setAccID] = useState(0);
    const [oldAccID, setOldAccID] = useState(0);
    const [oldPntID, setOldPntID] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [oldSdate, setOldSdate] = useState(null);
    const [oldEdate, setOldEdate] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [topoImg, setTopoImg] = useState('');
    const [openTopoImg, setOpenTopoImg] = useState(false);
    const [openTopoFile, setOpenTopoFile] = useState('');
    const [reqNum, setReqNum] = useState('');
    const [eyeType, setEyeType] = useState('');
    const [basepage1, setBasepage1] = useState('');
    const [basepage2, setBasepage2] = useState('');
    const [dropdown, setDropdown] = useState(true);
    const [showOption, setShowOption] = useState(false);
    const [showsuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showsuccessmsg ? success14 : null;
    const [loadingImg, setLoadingImg] = useState(false);
    const [showTopos, setShowTopos] = useState(false);
    const [topoInfo, setTopoInfo] = useState(null);
    const [topoName, setTopoName] = useState('');
    const [topoType, setTopoType] = useState('');
    const [pntName, setPntName] = useState('');

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const docs = [
        {
            uri: topoImg,
            fileName: reqNum + '_Datahub_report',
        },
    ];

    const options =
        accID !== 0 && accID === oldAccID && selectedPnt === oldPntID && startDate === oldSdate && endDate === oldEdate && !loading15
            ? rptData?.map((item) => {
                  const originalDate = new Date(item?.modified_on);
                  return {
                      label: (
                          <>
                              <span>{item.encoded_RequestNumber}</span> <span className='fs-14'>({originalDate?.toLocaleDateString()})</span>
                          </>
                      ),
                      value: item.id,
                      date: item.created_on,
                  };
              })
            : [];

    useEffect(() => {
        if (cmpreport?.data) {
            setCompareData(cmpreport?.data?.map((item) => item.req_info));
        }
    }, [cmpreport?.data]);

    const [reportItems, setReportItems] = useState([
        { id: 1, name: 'Interpretation', checked: true },
        { id: 2, name: 'MED', checked: true },
        { id: 3, name: 'Supplementation', checked: true },
        { id: 4, name: 'Lifestyle', checked: true },
        { id: 5, name: 'NFB', checked: false },
        { id: 6, name: 'PBM', checked: false },
        { id: 7, name: 'Images Only', checked: false },
    ]);

    const accountOptions = accTypes.map((item) => {
        return { value: item.id, label: item.acct_name };
    });

    const patientOptions = patientType.map((item) => {
        return { value: item.id, label: item.patient_name };
    });

    useEffect(() => {
        dispatch(AccountTypes());
    }, [dispatch]);

    function getPatientDetails(id) {
        dispatch(PatientTypes(id));
    }
    const handleAccountChange = (e) => {
        getPatientDetails(e);
        setAccID(e);
        form.resetFields(['patient']);
    };

    const changePnt = (id) => {
        setSelectedPnt(id);
        const pntNm = patientOptions?.filter((p) => p.value === id).map((p) => p.label);
        setPntName(pntNm[0]);
    };

    const getRequestDetail = (acid, pid, sdate, edate) => {
        let inputJson = {
            accountid: acid || 0,
            pnt_id: pid || '',
            start_date: sdate?.format('YYYY-MM-DD') || '',
            end_date: edate?.format('YYYY-MM-DD') || '',
        };
        setOldAccID(acid);
        setOldPntID(pid);
        setOldSdate(sdate);
        setOldEdate(edate);
        dispatch(getReportComparison(inputJson));
    };

    const resetFilter = () => {
        form.resetFields();
        setCompareData([]);
        setSelectedPnt(0);
        setOldAccID(0);
        setAccID(0);
        setOldPntID(0);
        setStartDate(null);
        setEndDate(null);
        setOldSdate(null);
        setOldEdate(null);
        setShowOption(false);
        setDropdown(true);
        setPntName('');
    };
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    const getReportDetails = () => {
        let inputJson = {
            servicerequestids: selectedRpts,
        };
        dispatch(getComparisonReport(inputJson));
        setShowsuccessmsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            setShowOption(true);
            setDropdown(false);
        }
    }, [successmsg]);

    const handleReports = (value) => {
        setSelectedRpts(value);
    };

    const submitFilter = async (values) => {
        let value = await form.validateFields();
        if (value) {
            if (options.length > 0 && accID === oldAccID && selectedPnt === oldPntID) {
                getReportDetails();
            } else {
                getRequestDetail(accID, selectedPnt, startDate, endDate);
            }
        }
    };

    const validateCheckboxGroup = (_, value) => {
        if (selectedRpts?.length >= 2 && selectedRpts?.length <= 3) {
            return Promise.resolve();
        } else if (selectedRpts?.length > 3) {
            return Promise.reject(new Error('Three reports are maximum'));
        }
        return Promise.reject(new Error('At least two reports must be selected'));
    };

    const handleItemChecked = (val) => (e) => {
        setReportItems((current) =>
            current.map((obj) => {
                if (obj.id === val.id) {
                    return { ...obj, checked: e.target.checked };
                }

                return obj;
            })
        );
    };
    const isOutsideRange = (value, min, max) => {
        return value < min || value > max;
    };
    const getStyle = (item, markerType, age) => {
        const eyeClosedThetaBeta = parseFloat(item.eyeclosed);
        const eyeOpenThetaBeta = parseFloat(item.eyeopen);
        const eyeData = markerType === 'eyeClosed' ? parseFloat(item.eyeclosed) : parseFloat(item.eyeopen);
        const eyeData1 = markerType === 'eyeClosed' ? item.eyeclosed === 'true' : item.eyeopen === 'true';
        let style = { lineHeight: 1.2 };
        if (item.markername === 'Alpha/beta ratio' && isOutsideRange(eyeData, 6, 12)) {
            style.color = 'red';
        } else if (item.markername === 'Posterior Dominant Rhythm') {
            let lowerBound, upperBound;
            if (age < 10) {
                lowerBound = 8;
                upperBound = 10;
            } else if (age >= 10 && age < 45) {
                lowerBound = 10;
                upperBound = 12;
            } else if (age >= 45 && age < 55) {
                lowerBound = 9.5;
                upperBound = 12;
            } else if (age >= 55 && age < 65) {
                lowerBound = 9;
                upperBound = 12;
            } else if (age >= 65 && age < 75) {
                lowerBound = 8.5;
                upperBound = 12;
            } else {
                lowerBound = 8;
                upperBound = 12;
            }

            if (isOutsideRange(eyeData, lowerBound, upperBound)) {
                style.color = 'red';
            }
        } else if (item.markername === 'Theta/beta ratio' && markerType == 'eyeClosed' && eyeClosedThetaBeta > 3) {
            style.color = 'red';
        } else if (item.markername === 'Theta/beta ratio' && markerType == 'eyeOpen' && eyeOpenThetaBeta > eyeClosedThetaBeta) {
            style.color = 'red';
        } else if (item.markername === 'Mu Rhythm Present' && eyeData1) {
            style.color = 'red';
        } else if (item.markername === 'F7>F8 Asymmetry Present' && eyeData1) {
            style.color = 'red';
        } else if (item.markername === 'F3>F4 Asymmetry Present' && eyeData1) {
            style.color = 'red';
        } else if (item.markername === 'P4>P3 Asymmetry Present' && eyeData1) {
            style.color = 'red';
        }
        return style;
    };

    const handleSDateChange = (e) => {
        setStartDate(e);
    };
    const handleEDateChange = (e) => {
        setEndDate(e);
    };

    const handleCancel = () => {
        setOpenTopoImg(false);
        setTopoImg('');
    };

    const showTopo = (pdr, eyetype, reqNo, rdate, tname, eofftabs, ecfftabs, eozabs, eczabs, eoabs, ecabs, eorel, ecrel, eofftrel, ecfftrel, eograph, ecgraph, dlen) => {
        setShowTopos(true);
        setTopoInfo({ pdr, eyetype, reqNo, rdate, eofftabs, ecfftabs, eozabs, eczabs, eoabs, ecabs, eorel, ecrel, eofftrel, ecfftrel, eograph, ecgraph, dlen });
        setTopoName(tname);
        setTopoType(eyetype);
    };

    return (
        <div>
            <div className="d-flex my-auto">
                <h5 className="my-auto">{showTopos ? <span className="text-capitalize">Topography Comparison</span> : 'Report Comparison'}</h5>
                {showTopos && (
                    <>
                        <h6 className="my-auto ms-auto ">
                            Patient Name: <span className="text-primary text-capitalize">{pntName}</span>
                        </h6>
                        <Button type="primary" className="ms-3" onClick={() => setShowTopos(!showTopos)}>
                            Back
                        </Button>
                    </>
                )}
            </div>
            {!showTopos ? (
                <div className="row m-0">
                    <div className="col-md-2 p-0 mt-2">
                        <div className="bg-white p-3 ">
                            <h5 className="txt-primary">Filter</h5>
                            <div>
                                <Form form={form} layout="vertical" onFinish={submitFilter}>
                                    <Form.Item
                                        name="account"
                                        label="Select Account"
                                        className="mb-3"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder=""
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                            notFoundContent={
                                                <div className="text-center p-4">
                                                    {loading2 ? (
                                                        <span>
                                                            <LoadingOutlined />
                                                            Loading...
                                                        </span>
                                                    ) : (
                                                        <span>No account available</span>
                                                    )}
                                                </div>
                                            }
                                            options={accountOptions}
                                            onChange={handleAccountChange}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="patient"
                                        label="Select Patient"
                                        className="mb-3 pb-1"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder=""
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                            notFoundContent={
                                                <div className="text-center p-4">
                                                    {loading4 ? (
                                                        <span>
                                                            <LoadingOutlined />
                                                            Loading...
                                                        </span>
                                                    ) : (
                                                        <span>No patient available</span>
                                                    )}
                                                </div>
                                            }
                                            onChange={changePnt}
                                            options={patientOptions}
                                            disabled={patientOptions ? false : true}
                                        />
                                    </Form.Item>
                                    {showOption && (
                                        <>
                                            <Divider className="mb-1" />
                                            <div className="d-flex justify-content-between pointer mb-2" onClick={toggleDropdown}>
                                                <span className="fw-bold">More Option</span>
                                                <span className="dropdown-icon">{dropdown ? <DownOutlined rotate={180} /> : <DownOutlined />}</span>
                                            </div>
                                        </>
                                    )}
                                    <div className={`dropdown-content ${dropdown ? 'show' : 'hide'}`}>
                                        <Form.Item name="startDate" label="Start Date" className="mb-3">
                                            <DatePicker format="MM-DD-YYYY" disabledDate={(current) => disabledDate(current)} onChange={handleSDateChange} />
                                        </Form.Item>
                                        <Form.Item name="endDate" label="End Date" className="mb-3">
                                            <DatePicker format="MM-DD-YYYY" disabledDate={(current) => disabledDate(current)} onChange={handleEDateChange} />
                                        </Form.Item>
                                        {options?.length > 0 && !loading15 ? (
                                            <Form.Item
                                                name="reports"
                                                label="Select Reports"
                                                className="mb-3 pb-0 rpt-section"
                                                rules={[
                                                    { required: true, message: 'This field is required' },
                                                    {
                                                        validator: validateCheckboxGroup,
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <span className="txt-warning font-size-12">Maximum report selection limit is 3</span>
                                                    <div className="bg-light p-2 phq-tbl">
                                                        <Checkbox.Group options={options} onChange={handleReports} className="row m-0"></Checkbox.Group>
                                                    </div>
                                                </div>
                                            </Form.Item>
                                        ) : oldPntID == selectedPnt && oldAccID == accID && accID !== 0 && startDate == null && !loading15 ? (
                                            <Form.Item name="reports" label="Select Reports" className="mb-3">
                                                <div className="p-4 text-center bg-light">Reports Not available</div>
                                            </Form.Item>
                                        ) : (
                                            ''
                                        )}
                                        {/* // {(oldPntID && compreport?.req_info) && ( */}

                                        {/* // )
                                // } */}
                                    </div>
                                    <div className="d-flex text-end">
                                        <Button className="bg-warning text-dark ms-auto" onClick={resetFilter}>
                                            Reset
                                        </Button>
                                        <Button className="ms-2" type="primary" htmlType="submit" loading={loading15 || loading14}>
                                            Apply Filter
                                        </Button>
                                        {/* {options?.length > 0 ? <Button type="primary" className="ms-2" key="submit" loading={loading14} onClick={getReportDetails}>Apply</Button> : <Button type="primary" key="submit" loading={loading13} className="ms-2" onClick={()=>getRequestDetail(accID,selectedPnt)}>Apply</Button>} */}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {compareData?.length > 0 && selectedPnt && (
                            <div className="mt-2 bg-white p-3">
                                <h6 className="txt-primary">Select Report Items</h6>
                                <div className="row m-0 phq-tbl">
                                    {reportItems?.map((item) => {
                                        return (
                                            <Checkbox key={item.id} defaultChecked={item.checked} onChange={handleItemChecked(item)}>
                                                {item.name}
                                            </Checkbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col ms-2 bg-white p-3 mt-2">
                        {compareData?.length > 0 && selectedPnt ? (
                            <div>
                                <h5 className="txt-primary">Result</h5>
                                <table className="w-100 table-bordered edf-step-header custom">
                                    <thead>
                                        <tr style={{ background: '#dbebf6' }}>
                                            <th className="p-2 border-end">Report Items</th>
                                            {compareData?.map((item, i) => {
                                                return (
                                                    <th className="text-center reqnum" colSpan={2} key={i + 1}>
                                                        {item.servicerequest_info?.encoded_RequestNumber}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr style={{ background: '#99C1E6' }}>
                                            <th className="p-2 fw-500">Released On</th>
                                            {compareData?.map((item, i) => {
                                                const originalDate = new Date(item.servicerequest_info?.created_on) || null;
                                                return (
                                                    <th className="text-center" colSpan={2} key={i + 2}>
                                                        {originalDate?.toLocaleDateString()}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {reportItems.map((report) => {
                                                if (!report.checked) return null;

                                                switch (report.name) {
                                                    case 'Interpretation':
                                                        return compareData[0]?.interpretationmakers?.map((item, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {index === 0 && (
                                                                        <tr className="headings">
                                                                            <td className="p-2 border-end">Interpretation</td>
                                                                            {compareData.map((data, i) => (
                                                                                <React.Fragment key={data.servicerequest_info?.id + i}>
                                                                                    <td className="text-center border-end">Eye Open</td>
                                                                                    <td className="text-center border-end">Eye Close</td>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </tr>
                                                                    )}
                                                                    <tr>
                                                                        <td className="ps-4 py-2">{item?.markername}</td>
                                                                        {compareData.map((data, i) => (
                                                                            <React.Fragment key={i}>
                                                                                <td className={`p-2 text-center text-capitalize ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                                                                    <div style={{ ...getStyle(item, 'eyeOpen', data?.servicerequest_info?.pnt_age) }}>
                                                                                        {data?.interpretationmakers[index]?.eyeopen
                                                                                            ? data?.interpretationmakers[index].mfieldtype === 'textbox'
                                                                                                ? parseFloat(data?.interpretationmakers[index]?.eyeopen)?.toFixed(2)
                                                                                                : data?.interpretationmakers[index]?.eyeopen
                                                                                            : '--'}
                                                                                    </div>
                                                                                </td>
                                                                                <td className={`p-2 text-center text-capitalize ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                                                                    <div style={{ ...getStyle(item, 'eyeClosed', data?.servicerequest_info?.pnt_age) }}>
                                                                                        {data?.interpretationmakers[index]?.eyeclosed
                                                                                            ? data?.interpretationmakers[index].mfieldtype === 'textbox'
                                                                                                ? parseFloat(data?.interpretationmakers[index]?.eyeclosed)?.toFixed(2)
                                                                                                : data?.interpretationmakers[index]?.eyeclosed
                                                                                            : '--'}
                                                                                    </div>
                                                                                </td>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        });

                                                    case 'NFB':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">NeuroFeedback</td>
                                                                    {compareData?.map((item, i) => (
                                                                        <React.Fragment key={item.servicerequest_info?.id + i}>
                                                                            <td className="text-center border-0"></td>
                                                                            <td className="text-center border-0"></td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Eye Open</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.neurofeedback_EO ? (
                                                                                <div dangerouslySetInnerHTML={{ __html: data?.neurofeedback_EO }}></div>
                                                                            ) : (
                                                                                <div className="col-md-12 text-center">--</div>
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Eye Closed</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.neurofeedback_EC ? (
                                                                                <div dangerouslySetInnerHTML={{ __html: data?.neurofeedback_EC }}></div>
                                                                            ) : (
                                                                                <div className="col-md-12 text-center">--</div>
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </React.Fragment>
                                                        );

                                                    case 'PBM':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                {/* PBM */}
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">Photobiomodulation</td>
                                                                    {compareData?.map((item, i) => (
                                                                        <React.Fragment key={item.servicerequest_info?.id + i}>
                                                                            <td className="text-center border-0"></td>
                                                                            <td className="text-center border-0"></td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Pulse Rate (Hz)</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.photobiomodulation_info?.pulse_rate || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Intensity (%)</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.photobiomodulation_info?.intensity || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Location</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.photobiomodulation_info?.location || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Duration (min)</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data?.photobiomodulation_info?.duration || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    /* Supplement */
                                                    case 'Supplementation':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                {/* Supplementation */}
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">Supplementation</td>
                                                                    {compareData?.map((item, i) => (
                                                                        <React.Fragment key={item.servicerequest_info?.id + i}>
                                                                            <td className="text-center border-0"></td>
                                                                            <td className="text-center border-0"></td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Supplement profile matched to EEG patterns</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'} p-2`} colSpan={2}>
                                                                            {data.com_mdnutritional_supplementation_templ
                                                                                ?.filter((item) => item.ischoices === true && item.nutritional_supplementation_name)
                                                                                ?.map((item, i) => item.nutritional_supplementation_name)
                                                                                .join(', ') || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    /* Lifestyle */
                                                    case 'Lifestyle':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                {/* Lifestyle */}
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">Lifestyle</td>
                                                                    {compareData?.map((item, i) => (
                                                                        <React.Fragment key={item.servicerequest_info?.id + i}>
                                                                            <td className="text-center border-0"></td>
                                                                            <td className="text-center border-0"></td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Lifestyle profile matched to EEG patterns</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <td key={i} className={`p-2 ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`} colSpan={2}>
                                                                            {data.com_lifestyle_templ
                                                                                ?.filter((item) => item.ischoices === true && item.lifestyle_name)
                                                                                ?.map((item, i) => item.lifestyle_name)
                                                                                .join(', ') || <div className="col-md-12 text-center">--</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    /* Medication */
                                                    case 'MED':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                {/* Recommended Medications */}
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">Recommended Medications</td>
                                                                    {compareData?.map((item, i) => (
                                                                        <React.Fragment key={item.servicerequest_info?.id + i}>
                                                                            <td className="text-center border-0"></td>
                                                                            <td className="text-center border-0"></td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <td className="ps-4 py-2">Neurology review and further workup or treatment recommendations</td>
                                                                    {compareData?.map((data, i) => (
                                                                        <React.Fragment key={i}>
                                                                            <td className={`${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'} p-2`} colSpan={2}>
                                                                                {data.com_medic_templ
                                                                                    ?.filter((item) => item.ischoices === true)
                                                                                    ?.map((item, i) => item.medication_name)
                                                                                    .join(', ') || <div className="col-md-12 text-center">--</div>}
                                                                            </td>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    case 'Images Only':
                                                        return (
                                                            <React.Fragment key={report.id}>
                                                                <tr className="headings">
                                                                    <td className="p-2 border-0">Topography</td>
                                                                    <td className="text-start border-0" colSpan={compareData?.length * 3}>
                                                                        <span
                                                                            className="text-primary text-decoration-underline pointer fs-14"
                                                                            onClick={() =>
                                                                                showTopo(
                                                                                    compareData.map((data) => data.EC_pdr_path),
                                                                                    'eyeclose',
                                                                                    compareData.map((val) => val.servicerequest_info?.encoded_RequestNumber),
                                                                                    compareData.map((item) => item.servicerequest_info?.created_on),
                                                                                    'PDR-Topography',
                                                                                    compareData.map((data) => data.FFT_absolute_power_path),
                                                                                    compareData.map((data) => data.EC_FFT_absolute_power_path),
                                                                                    compareData.map((data) => data.EO_Z_scored_FFT_absolute_power),
                                                                                    compareData.map((item) => item.EC_Z_scored_FFT_absolute_power),
                                                                                    compareData.map((data) => data.EO_absolutepow_path),
                                                                                    compareData.map((item) => item.EC_absolutepow_path),
                                                                                    compareData.map((item) => item.EO_relativepow_path),
                                                                                    compareData.map((item) => item.EC_relativepow_path),
                                                                                    compareData.map((item) => item.EO_FFT_relative_power_path),
                                                                                    compareData.map((item) => item.EC_FFT_relative_power_path),
                                                                                    compareData.map((item) => item.EO_result_edf_graph),
                                                                                    compareData.map((item) => item.EC_result_edf_graph),
                                                                                    cmpreport?.data?.length
                                                                                )
                                                                            }
                                                                        >
                                                                            Click here to see the comparison result <FileTextOutlined size={12} />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="text-center mt-4">
                                <Image src={NoReportImg} preview={false} />
                                <h5 className="text-secondary">Select Reports to compare</h5>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <CompareTopography data={{ topoInfo: topoInfo }} />
            )}
            <Modal
                title={modalTitle}
                open={openTopoImg}
                width={1000}
                onCancel={handleCancel}
                footer={[
                    <Button type="primary" key="02" className="bg-danger text-white" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >
                <div className="bg-light p-2 ">
                    <div>
                        <span>Request Number: {reqNum}</span>
                    </div>
                    <div>
                        <span> Eye Condition: {eyeType}</span>
                    </div>
                </div>
                <div className="d-flex output-modal border-top edf-img-container">
                    {openTopoFile === '.png' ? (
                        <Image className="col " src={topoImg} height="auto" />
                    ) : openTopoFile === '.pdf' ? (
                        <DocViewer
                            documents={docs}
                            initialActiveDocument={docs[1]}
                            pluginRenderers={DocViewerRenderers}
                            theme={{
                                primary: '#5a53b2',
                                secondary: '#ffffff',
                                tertiary: 'rgb(102 96 176 / 40%)',
                                textPrimary: '#ffffff',
                                textSecondary: '#5a53b2',
                                textTertiary: '#ffffff',
                                disableThemeScrollbar: true,
                            }}
                        />
                    ) : (
                        <div className="edf-img-container">
                            <Spin spinning={loadingImg}>
                                <Image className="" src={`data:image/png;base64,${basepage1}`} />
                                <Image className="" src={`data:image/png;base64,${basepage2}`} />
                            </Spin>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
