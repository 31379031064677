import ReactQuill ,{Quill} from 'react-quill';
import config from 'assets/config';
import { useDispatch, useSelector } from 'react-redux';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';
import Skeleton from 'antd/es/skeleton';
import Empty from 'antd/es/empty';
import Result from 'antd/es/result';
import Notification  from "antd/es/notification";

import 'react-quill/dist/quill.snow.css';
import 'antd/es/message/style';
import 'antd/es/spin/style';
import 'antd/es/skeleton/style';
import 'antd/es/empty/style';
import 'antd/es/result/style';

const renderNode = () => (
    <Empty className='p-2' description="No Request Available" />
);
const customMessage = () => (
    <Empty className='p-2' description="No Patient Available" />
);
const medicationMessage = () => (
    <Empty className='p-2' description="No Medication Available" />
);
const backendURL = config.apiURL;
const url1 = backendURL.slice(0, backendURL.lastIndexOf('/'));
const url2 = url1.slice(0, url1.lastIndexOf('/'));

export{
    renderNode,
    customMessage,
    medicationMessage,url2,ReactQuill,Spin,Skeleton,
    useDispatch,useSelector,message,Result,Empty,Quill,
    Notification
}