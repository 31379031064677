import React from 'react';
import { Drawer, Avatar } from 'components/elements/AntComponent';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

export default function NotificationDrawer(props) {
    const tagColors = ['#ffd9dc', '#dedef9', '#dcf0fd', '#fbf1d7'];
    const history = useNavigate();

    const handleNotification = (itm, item) => {
        if (item?.due_invoice_flag || item?.aged_invoice_flag || item?.waiting_invoice_flag || item?.generate_invoice_flag) {
            history('/invoice-manager');
        } else if (item?.complete_job_flag || item?.error_request_flag) {
            history(`/edf_job_manager/edf-processing`, {
                state: {
                    sid: itm?.job_reqid,
                    reqFrom: itm?.request_from,
                    is_billing: itm?.is_billing,
                },
            });
        } else if (item?.new_request_flag) {
            sessionStorage.setItem('reqId', itm.id);
            history(`/view-request/pipeline-request`, {
                state: {
                    reqId: itm?.service_request_id,
                    request_from: itm?.request_from,
                    archive: false,
                    is_archive: itm?.archive_data,
                    is_billing: itm?.is_billing,
                },
            });
        } else if (item?.released_request_flag) {
            history(`/released-request/dataset-information`, {
                state: {
                    reqId: itm?.service_request_id,
                    isStaff: sessionStorage.getItem('role') === 'staff' ? true : false,
                },
            });
        } else if (item?.intake_request_flag) {
            history(`/order-management`, {
                state: {
                    reqId: itm?.service_request_id,
                    status: itm?.status === 'Request Init' ? false : true,
                    active: itm?.is_active,
                    error: false,
                    requestFrom: itm?.request_from,
                },
            });
        } else if (item?.report_item_flag) {
            history(`/report-rate`, {
                state: {
                    serviceReqID: itm?.service_request_id,
                    accountId: itm?.accountid,
                    isUpdate: true,
                    details: itm,
                },
            });
        }
        props?.data?.closeDrawer();
    };

    return (
        <div>
            <Drawer title="Notifications" width={500} open={props.data.openDrawer} onClose={() => props.data.closeDrawer()}>
                <Spin spinning={props.data.loading1}>
                    {props.data?.notifyData?.length > 0 ? (
                        <>
                            {props?.data?.notifyData?.map((item, index) => {
                                return (
                                    <div className="border-bottom pb-3" key={item.id}>
                                        <div className="d-flex ps-2 pt-3 flex-wrap">
                                            <div className="col-auto my-auto me-2">
                                                <Avatar className="text-capitalize fw-bold" size={40} style={{ backgroundColor: `${tagColors[index % tagColors.length]}`, color: '#888888' }}>
                                                    {item.label_name[0]}
                                                </Avatar>
                                            </div>
                                            <div className="col">
                                                <h6 className="text-dark text-capitalize mb-0 mt-2 fw-600 fs-16">{item.label_name}</h6>
                                                <span className="fs-13">
                                                    <span className="fw-500 text-gray fs-14" style={{ color: '#888' }}>
                                                        {item.content}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-break px-2 text-wrap word-wrap mt-1 ms-5">
                                            <div className="notify-height fs-15">
                                                <div className="d-flex flex-wrap">
                                                    {item.info && Array.isArray(item.info) ? (
                                                        item.info.map((itm, i) => (
                                                            <React.Fragment key={i}>
                                                                <span className={`fs-14 text-capitalized pe-2`} key={i} onClick={() => handleNotification(itm, item)}>
                                                                    <a className="text-decoration-underline">
                                                                        {itm?.invoice_number || itm?.encoded_request_number || itm?.job_req_number}
                                                                        {i < item.info.length - 1 && ', '}
                                                                    </a>
                                                                </span>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        <p className="text-center p-3 w-100">Details not found</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div className="text-center p-5 fs-18 text-secondary fw-500 mt-5">Notifications not available</div>
                    )}
                </Spin>
            </Drawer>
        </div>
    );
}
