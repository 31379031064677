import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoutes from 'components/routes/PrivateRoutes';
import EdfViewer from 'components/edfViewer/EdfViewer';
//css
import 'assets/styles/customTheme.scss';
import 'assets/styles/tableStyle.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'antd/dist/reset.css';
import SendEmailForm from 'components/auth/SendEmailForm';
import ResetPassword from 'components/auth/ResetPassword';
import PsychedelicSearch from 'components/psychedelic/PsychedelicSearch';
;

//components
const Login = lazy(() => import('components/auth/Login'));
const EmailSentForm = lazy(() => import('components/auth/EmailSentForm'));
const ChangePasswordScreen = lazy(() => import('components/auth/ChangePassword'));
const Page404 = lazy(() => import('components/auth/Page404'));
const PatientIntakeForm = lazy(() => import('components/PatientIntakeForm'));

function App() {
    // const isOnline = useNetworkStatus();
    const isAuth = localStorage?.getItem('token');
    const { userInfo } = useSelector((state) => state.auth);

    return (
        <div className="App">
            {/* {isOnline ? ( */}
            <Routes>
                <Route exact path="/login" element={<Login data={{ isLoggedIn: isAuth }} />}></Route>
                <Route path="/reset-password" element={<SendEmailForm />}></Route>
                <Route path="/email-sent" element={<EmailSentForm />}></Route>
                <Route path="/change-password/:id" element={<ChangePasswordScreen />}></Route>
                <Route path="/page404" element={<Page404 />}></Route>
                <Route path="/patient-form/:id" element={<PatientIntakeForm />}></Route>
                <Route path="/edf" element={<EdfViewer />}></Route>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/change-default-password" element={<ResetPassword />} />
                <Route path="/psychedelic-search" element={<PsychedelicSearch />} />
                {isAuth || userInfo ? <Route path="*" element={<PrivateRoutes />} /> : <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
            {/* ) : (
                <p>You are offline. Please check your internet connection.</p>
            )} */}
        </div>
    );
}

export default App;
