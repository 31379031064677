import React from 'react';
import EEGImage from 'assets/images/brandname.svg';
import { Input } from 'components/elements/FormComponent';
import { SearchOutlined } from 'components/elements/IconComponent';

export default function PsychedelicSearch() {
    return (
        <div>
            <div className="topbar bg-white py-2 px-4 shadow-sm d-flex">
                <img className="logo" src={EEGImage} alt="EEG Logo" height="40px" />
                <div className="ms-auto my-auto col-auto justify-content-between d-flex">
                    <a className="me-3">Home</a>
                    <a className="me-3">Favorites</a>
                    <a className="me-3">Bookmarks</a>
                    <Input prefix={<SearchOutlined />} placeholder="Search topics" />
                </div>
            </div>
            <div className='p-4 bg-light mt-2'>

            </div>
        </div>
    );
}
