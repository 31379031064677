import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
const backendURL = config.apiURL;

export const getOpenInvoice = createAsyncThunk('get-open-invoice', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/get_invoice_grid_new`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getInvoiceApprove = createAsyncThunk('get-invoice-approve', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/approve_invoice_update`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const proceedToInvPayment = createAsyncThunk('procedd-to-invoice-payment', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/pay_inv_payment`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const updatePaymentDetail = createAsyncThunk('update-payment-status', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/update_payment`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getInvStatistic = createAsyncThunk('get-invoice-statisic', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_invoice_statistics/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const saveBillingMethod = createAsyncThunk('save-billing-method', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/set_billing_service`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getBillingMethod = createAsyncThunk('get-billing-method', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_billing_service/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const generateInvocie = createAsyncThunk('generate-invoice', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/generate_invoice`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getAwaitingInvoice = createAsyncThunk('awaiting-generate-invoice', async (id, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/waiting_for_invoice_new`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const generateCustomInvoice = createAsyncThunk('generate-custom-invoice', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/generate_custom_invoice`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getInvoiceInfo = createAsyncThunk('get-invoice-info', async (id, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/invoice_info/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const updateInvoicePrice = createAsyncThunk('update-invoice-price', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/invoice_price_update`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
