import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'store/slices/authSlices';
import dashboardReducer from 'store/slices/dashboardSlices';
import menuReducer from 'store/slices/menuServiceSlice';
import accountReducer from 'store/slices/account/accountSlices';
import commonServicesReducer from 'store/slices/commonServicesSlices';
import patientReducer from 'store/slices/patient/patientSlices';
//master data
import diagnosisReducer from 'store/slices/master-data/diagnosisSlices';
import SymptomsReducer from 'store/slices/master-data/SymptomsSlices';
import LifestyleReducer from 'store/slices/master-data/LifestyleSlices';
import SupplementReducer from 'store/slices/master-data/supplementSlices';
import MarkerReducer from 'store/slices/master-data/MarkerSlices';
import InterpretationReducer from 'store/slices/master-data/InterpretationSlices';
import MedicationReducer from 'store/slices/master-data/MedicationSlices';
import pipelinereqReducer from 'store/slices/pipeline-request/pipelinereqSlices';
import wizardReducer from 'store/slices/pipeline-request/wizardSlices';
import ReqInfoSlices from 'store/slices/pipeline-request/wizard-slices/ReqInfoSlices';
import associateDocumentReducer from 'store/slices/pipeline-request/wizard-slices/associateDocumentSlices';
import RecordingAnalysisReducer from 'store/slices/pipeline-request/wizard-slices/RecordingAnalysisSlices';
import InterpretReducer from 'store/slices/pipeline-request/wizard-slices/InterpretSlices';
import releasedReducer from 'store/slices/service-request/releasedRequestSlices';
import downloadReducer from 'store/slices/downloadFile';
import uploadReducer from 'store/slices/uploadFile';
import orderReducer from 'store/slices/orderManagementSlices';
import newRequestSlices from 'store/slices/service-request/newRequestSlices';
import approveRequestReducer from 'store/slices/pipeline-request/wizard-slices/approveRequestSlices';
import Phq8Reducer from 'store/slices/master-data/Phq8Slice';
//job manager
import JobManagerListReducer from 'store/slices/jobmanager/jobManagerSlices';
import edfDoclistReducer from 'store/slices/pipeline-request/edfProcessSlices';
//search
import SearchReducer from 'store/slices/SearchSlices';
import AmazonSearchListReducer from 'store/slices/amazon-search/amazonSearchSlices';
import MedicTempReducer from 'store/slices/master-data/MedicationTempSlices';
// billing
import CreditReducer from 'store/slices/billing/creditMangerSlice';
import InvoiceReducer from 'store/slices/billing/invoiceManagerSlice.js';

const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    dashboard: dashboardReducer,
    account: accountReducer,
    patient: patientReducer,
    //master data
    diagnosis: diagnosisReducer,
    symptoms: SymptomsReducer,
    lifestyle: LifestyleReducer,
    supplement: SupplementReducer,
    marker: MarkerReducer,
    interpretation: InterpretationReducer,
    medication: MedicationReducer,
    phq8: Phq8Reducer,
    medicTemp: MedicTempReducer,
    //released request
    releasedInfo: releasedReducer,
    request: newRequestSlices,
    //pipeline request
    pipeline: pipelinereqReducer,
    wizard: wizardReducer,
    reqInfo: ReqInfoSlices,
    associateDoc: associateDocumentReducer,
    recAnalysis: RecordingAnalysisReducer,
    interpret: InterpretReducer,
    approveReq: approveRequestReducer,
    //common service
    commonData: commonServicesReducer,
    download: downloadReducer,
    upload: uploadReducer,
    //order
    order: orderReducer,

    //edf
    jobManager: JobManagerListReducer,
    doclistEdf: edfDoclistReducer,
    //search
    search: SearchReducer,
    amazonSearchGrid: AmazonSearchListReducer,
    //billing 
    creditManager: CreditReducer,
    invoiceManager: InvoiceReducer
  }
})

export default store;
